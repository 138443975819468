html, body, div, span, applet, object, iframe,
* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  font-family: 'Oxygen', sans-serif;
	vertical-align: baseline;
	box-sizing: border-box;
}

#root {
	width: 100%;
	/* min-width: 100vw; */

	height: 100%;
	min-height: 100vh;
}

::placeholder {
	color: rgba(22, 22, 22, 0.25);
}

.react-datepicker__portal {
	width: 100% !important;
	height: 100% !important;
}